<template>
  <div :class="[validity]" class="t-selectable-group">
    <div>
      <component
        :is="getVariantComponent(choice)"
        v-for="choice in choices"
        :id="choice.id"
        :key="`${choice.id}_${choice.label}`"
        :is-checkbox="multiple"
        :is-selected="isSelected(choice.id)"
        :label="choice.label"
        :options="choice.options"
        :value="getInnerValueForChoice(choice)"
        class="t-selectable-group__selectable"
        @change="onValueChanged"
        @selected="onSelect"
      />
    </div>

    <TSelectableGroupHelp :value="value" :choices="choices" />
  </div>
</template>

<script>
import TSelectable from 'chimera/all/components/elements/TSelectable'
import { Selectable } from 'chimera/all/components/models/Selectable'
import TSelectableGroupHelp from 'chimera/all/components/elements/TSelectableGroupHelp'

export default {
  name: 'TSelectableGroup',

  components: {
    TSelectable,
    TSelectableGroupHelp,
  },

  props: {
    choices: {
      type: Array,
      required: true,
    },

    value: {
      type: Array,
      required: true,
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    defaultChoiceSelected: {
      type: Boolean,
      default: true,
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      innerValue: [],
    }
  },

  computed: {
    /**
     * @returns {string}
     */
    validity() {
      const hasErrors = this.errorMessages.length > 0
      return hasErrors ? 't-selectable-group--error' : ''
    },
  },

  watch: {
    /**
     * @param {Array} value
     */
    value(value) {
      this.innerValue = value
    },
  },

  /**
   */
  mounted() {
    this.initDefaultChoice()
  },

  methods: {
    /**
     *
     */
    initDefaultChoice() {
      if (
        this.innerValue.length > 0 ||
        this.multiple ||
        !this.defaultChoiceSelected
      ) {
        return
      }

      const choice = this.getDefaultChoice(this.choices)
      if (choice) {
        const { id, value } = choice
        this.onSelect(
          {
            id,
            value,
          },
          false,
        )
        this.onValueChanged({
          id,
          value,
        })
      }
    },

    /**
     * Handles selection event and optionally tracks it if initiated by the user.
     *
     * @param {{id: string, value: string, mouseEvent: MouseEvent}} selectionEvent - The selection event details.
     * @param {boolean} isUserAction - Whether the selection was made by the user (default: true).
     */
    onSelect({ id, value, mouseEvent }, isUserAction = true) {
      const selectionItem = {
        id,
        value,
      }

      this.innerValue = this.multiple
        ? this.toggleArrayForValue(this.innerValue, selectionItem)
        : [selectionItem]

      if (isUserAction) {
        this.trackSelectable(mouseEvent, id)
      }
    },

    /**
     * @param {{id: string, value: string}} selection
     */
    onValueChanged(selection) {
      this.innerValue = [...this.innerValue].map((value) => {
        if (value.id === selection.id) {
          value.value = selection.value
        }

        return value
      })

      this.$emit('input', this.innerValue)
    },

    /**
     * @param {MouseEvent} mouseEvent
     * @param {string} elementId
     */
    trackSelectable(mouseEvent, elementId) {
      const metadata = {}

      let elementType = 'radio'
      if (this.multiple) {
        elementType = 'checkbox'

        const isSelected = this.isSelected(elementId)
        metadata.state = isSelected ? 'on' : 'off'
      }

      this.$eventBus.emitClickEvent(
        mouseEvent,
        elementId,
        elementType,
        metadata,
      )
    },

    /**
     *
     * @param {Selectable} choice
     * @returns {string}
     */
    getInnerValueForChoice(choice) {
      const innerValueChoiceFound = this.innerValue.find(
        (value) => value.id === choice.id,
      )
      return innerValueChoiceFound ? innerValueChoiceFound.value : choice.value
    },

    /**
     * @param {Selectable[]} choices
     * @returns {Selectable|undefined}
     */
    getDefaultChoice(choices) {
      if (choices.length === 0) {
        return undefined
      }

      const choiceWithDefaultFlag = choices.filter(
        (choice) => choice.options && choice.options.isDefault,
      )[0]
      return choiceWithDefaultFlag || choices[0]
    },

    /**
     * @param {Selectable} choice
     * @returns {object}
     */
    getVariantComponent(choice) {
      if (choice.options && choice.options.component) {
        return choice.options.component
      }

      return TSelectable
    },

    /**
     * @param {string} id
     * @returns {boolean}
     */
    isSelected(id) {
      return this.innerValue.filter((value) => id === value.id).length > 0
    },

    /**
     * Handles the state for multiple selections
     *
     * @param {Array} array
     * @param {any} value
     * @returns {Array}
     */
    toggleArrayForValue(array, value) {
      const items = [...array]

      // Value already exists in the given items. Let's remove it.
      const valueAlreadyExists =
        items.filter((item) => item.id === value.id).length > 0
      if (valueAlreadyExists) {
        return items.filter((item) => item.id !== value.id)
      }

      items.push(value)
      return items
    },
  },
}
</script>
