<template>
  <div class="service-form-part">
    <ServiceSelectionField
      :choices="serviceSelections"
      v-on="$listeners"
      @result="setServiceForSelection($event.value[0])"
    />

    <ServiceField ref="service" hidden v-on="$listeners" />
  </div>
</template>

<script>
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import {
  drivingSchoolLicenseANL,
  drivingSchoolLicenseBENL,
  drivingSchoolLicenseBNL,
  drivingSchoolLicenseCCEDDENL,
} from 'chimera/drivingSchool/service'
import ServiceSelectionField from 'chimera/all/components/form/fields/serviceSelection/ServiceSelectionField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'ServiceRadioFormPart',

  components: {
    ServiceSelectionField,
    ServiceField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      serviceSelections: [
        new Selectable(
          'Rijbewijs A',
          'Rijbewijs A',
          drivingSchoolLicenseANL.id,
          {
            tooltip: 'Het rijbewijs voor motoren.',
          },
        ),
        new Selectable(
          'Rijbewijs B',
          'Rijbewijs B',
          drivingSchoolLicenseBNL.id,
          {
            subtext: '(Auto rijbewijs)',
            tooltip: "Het rijbewijs voor auto's.",
            isDefault: true,
          },
        ),
        new Selectable(
          'Rijbewijs BE',
          'Rijbewijs BE',
          drivingSchoolLicenseBENL.id,
          {
            tooltip: 'Het rijbewijs voor auto’s met aanhanger.',
          },
        ),
        new Selectable(
          'Rijbewijs C',
          'Rijbewijs C',
          drivingSchoolLicenseCCEDDENL.id,
          {
            tooltip: 'Het rijbewijs voor campers en/of (vracht)wagens.',
          },
        ),
        new Selectable(
          'Rijbewijs CE',
          'Rijbewijs CE',
          drivingSchoolLicenseCCEDDENL.id,
          {
            tooltip:
              'Het rijbewijs voor campers en/of (vracht)wagens met aanhanger.',
          },
        ),
        new Selectable(
          'Rijbewijs D',
          'Rijbewijs D',
          drivingSchoolLicenseCCEDDENL.id,
          {
            tooltip:
              'Het rijbewijs voor bussen (officieel personenvervoer van meer dan 8 personen).',
          },
        ),
        new Selectable(
          'Rijbewijs DE',
          'Rijbewijs DE',
          drivingSchoolLicenseCCEDDENL.id,
          {
            tooltip:
              'Het rijbewijs voor bussen (officieel personenvervoer van meer dan 8 personen) met aanhanger.',
          },
        ),
      ],
    }
  },

  methods: {
    /**
     * @param {object}  type
     */
    setServiceForSelection(type) {
      if (!type) {
        return
      }

      const serviceSelection = this.serviceSelections.find(
        (serviceSelection) => serviceSelection.id === type.id,
      )
      this.$refs.service.setValue(serviceSelection.value)
    },
  },
}
</script>
