<template>
  <div :class="[`field-${field}`]" />
</template>

<script>
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'service'
export default {
  name: 'ServiceField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field,
    },

    isApiValidated: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    value: '',
  }),

  methods: {
    /**
     * Includes workaround for landing pages
     * @param {Array} value
     */
    afterOnValid(value) {
      // Store selected service.
      let serviceId = value
      if (typeof value === 'object') {
        serviceId = value[0].value
      }

      this.$store.dispatch('service/set', serviceId)
    },
  },
}
</script>
