<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <ServiceRadioFormPart @change="onChange" @result="onResult" />
    </t-form>
    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import ServiceRadioFormPart from 'chimera/drivingSchool/components/form/parts/service/ServiceRadioFormPart'
import NextStep from '~/pages/offertes-aanvragen/je-gegevens'

export default {
  name: 'ServiceTypeFormStep',

  components: {
    ServiceRadioFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  /**
   * @returns {object}
   */
  data() {
    return {
      willTransitionOnValid: true,
      customErrorMessage: ['Controleer je gegevens'],
    }
  },

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>
